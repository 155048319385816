/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
window.$ = window.jQuery = require("jquery");
require('jquery-ui');
require('jquery-validation');
require("jquery-validation/dist/additional-methods.js")
require('@popperjs/core/dist/umd/popper.js');
require('bootstrap');
require('bootstrap-datepicker');
require('bootstrap-datepicker/js/locales/bootstrap-datepicker.pt');
require('./datepicker.js');
require('select2/dist/js/select2.full');
require('select2/dist/js/i18n/pt');
require('./messages_en_EN.js');

if (session_locale == 'pt') {
    require('./messages_pt_PT.js');
}

window.step_attachments = require('./step_attachments.js');
portugueseHumanTaxIdValidator = require('./isAPortugueseHumanTaxIdValidator.js');

function updateTextAreaHeightSize() {
    $('textarea').each(function() {
        this.style.height = this.scrollHeight + "px";
    });
}

jQuery().ready(function() {
    /* FLASH MESSAGES START */
    $('#sf_admin_flashes .info').slideDown('fast').delay(6000).slideUp('fast');
    $('#sf_admin_flashes .notice').slideDown('fast').delay(6000).slideUp('fast');
    $('#sf_admin_flashes .error').slideDown('fast').delay(6000).slideUp('fast');

    if ($('#sf_admin_flashes').length)
    {
        var top=$('#sf_admin_flashes').position().top;
        $(document).scroll(function() {
            if($(document).scrollTop()>=top){
                $('#sf_admin_flashes').addClass('topfixed');
            }
            else{
                $('#sf_admin_flashes').removeClass('topfixed');
            }
        });
    }
    /* FLASH MESSAGES END */

    /* FORM VALIDATION SETTINGS START */
    jQuery.validator.setDefaults({
        errorPlacement: function(error, element) {
            if ($(element).hasClass('valid')){
                return;
            }

            var targetElem = element;

            if (element.attr("type") == "checkbox") {
                targetElem = element.next("label");
            }

            if (element.attr("type") == "radio") {
                targetElem = element.parent().parent();
            }

            var errorHtml = $('<div id="validation-error"></div>')
            .append('<span class="fa fa-times"></span>')
            .append(error);

            if (targetElem.is("select")) {
                errorHtml.addClass("select-field-validation-error");
            }

            if (targetElem.parent().hasClass('currency-group'))
            {
                errorHtml.insertAfter(targetElem.parent());
            } else {
                errorHtml.insertAfter(targetElem);
            }
        },
        errorClass: "custom-form-error",
        success: function(error, element) {
            $(error)
            .closest('#validation-error')
            .remove();
        }
    });

    jQuery.validator.addMethod("validate-nif", function(value, element) {
        return portugueseHumanTaxIdValidator.validate(value);
    }, "O NIF não é válido.");

    $('select').on('change', function() {
        $(this).next('#validation-error').remove();
    });


    /* FORM VALIDATION SETTINGS END */

    /* FORM SUBMISSION CONFIRMATION POPUP START */
    $("form[name!='contract_cessation_request'][name!='contract_details_request'][name!='code_verification']").validate({
        submitHandler: function (form) {
            $("#formSubmissionConfirmationModal").modal('show');
            $('#confirmationSubmitButton').click(function () {
                form.submit();
            });
        }
    });
    /* FORM SUBMISSION CONFIRMATION POPUP END */

    /* UPDATE TEXT AREA INPUTS FIELDS HEIGHT START */
    $('textarea').on('input', function() {
        this.style.height = "auto";
        this.style.height = this.scrollHeight + "px";
    });

    updateTextAreaHeightSize();
    /* UPDATE TEXT AREA INPUTS FIELDS HEIGHT END */

    /* Control invisible nested forms display according to the choice selected */
    var choiceWidget = $('.choiceInput:first').parent();
    var nestedFormSection1 = $('#nested_form_section_1');
    var nestedFormSection2 = $('#nested_form_section_2');
    var nestedFormSection1_help = $('#contract_cessation_request_doYouWantToCeaseContract_help');
    choiceWidget.change(function () {
        if (choiceWidget.find('input[type=\'radio\']:checked').val() == 0) {
            nestedFormSection1.addClass('d-none');
            nestedFormSection2.removeClass('d-none');

            nestedFormSection1_help.removeClass('d-none');
            $('#contract_cessation_request_save').addClass('d-none');
            $('#contract_cessation_request_cancel').removeClass('d-none');
        } else if (choiceWidget.find('input[type=\'radio\']:checked').val() == 1) {
            nestedFormSection1.removeClass('d-none');
            nestedFormSection2.addClass('d-none');

            nestedFormSection1_help.addClass('d-none');
            $('#contract_cessation_request_save').removeClass('d-none');
            $('#contract_cessation_request_cancel').addClass('d-none');
        }

        $('div#validation-error').remove();
        updateTextAreaHeightSize();
    }).trigger('change');

    /* accordion history icon toggle*/
    $('.accordion').children().on('click', function() {
        $(this).find('i')
            .toggleClass('fa fa-angle-down')
            .toggleClass('fa fa-angle-up')
    });
});
