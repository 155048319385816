function validate(taxId){
    if (isValidPortugueseTaxId(taxId) && isOfAnHuman(taxId)) {
        return true;
    }

    return false;
}

function isValidPortugueseTaxId(taxId) {
    taxId = typeof taxId === 'string' ? taxId : taxId.toString();
    taxId = taxId.trim();
    var taxIdSplit = taxId.split("");
    var taxIdFirstDigits = ['1', '2', '3', '5', '6', '7', '8', '9'];

    if (!isNaN(taxId) && taxId.length == 9 && taxIdFirstDigits.includes(taxIdSplit[0])) {
        var checkDigit = 0;

        // Extract the next digit and multiply by the counter.
        for (var i = 0; i < 8; i++) {
            checkDigit += taxIdSplit[i] * (10 - i - 1);
        }

        // Establish check digits subtracting modulus 11 from 11.
        checkDigit = 11 - (checkDigit % 11);
        checkDigit = checkDigit >= 10 ? 0 : checkDigit;

        // Compare it with the last character of the VAT number. If it is the same,
        // then it's a valid check digit.
        if (checkDigit == taxIdSplit[8]) {
            return true;
        }
    }

    return false;
}

function isOfAnHuman(taxId){

    if(taxId != null && taxId.length > 0 && taxId.charAt(0) < 5 && taxId.charAt(0) > 0){
        return true;
    }
    return false;
}

module.exports.validate = validate;