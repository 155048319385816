function getFormName() {
    return $('form').attr('name');
}

function getAttachmentsSectionId() {
    return getFormName() + '_documents';
}

function isValidInputRow(index) {
    var mainSelector = '#' + getAttachmentsSectionId();
    var isDescriptionValid = $(mainSelector + '_' + index + '_description').valid();
    var isFileValid = $(mainSelector + '_' + index + '_file_file').valid();

    return isDescriptionValid && isFileValid;
}

function stageRow(index) {
    var mainSelector = '#' + getAttachmentsSectionId();
    $(mainSelector + '_' + index + '_description')
    .hide()
    .after('<p class="pl-2">' + $(mainSelector + '_' + index + '_description').val() + '</p>');

    $(mainSelector + '_' + index + '_file_file')
    .hide()
    .after('<i class="pl-2">' + $(mainSelector + '_' + index + '_file_file')[0].files[0].name + '</i>')
    .parent()
    .prev('.custom-file-label')
    .hide();
}

function getNextIndex() {
    var attachmentsId = getAttachmentsSectionId();
    var max = 0;
    var regExp = new RegExp('^' + attachmentsId + '_(\\d)+$')

    $('[id*="' + attachmentsId + '_"]').each(function(index, elem){
        var match = $(elem).attr('id').match(regExp);

        if (match !== null) {
            index = parseInt(match[1], 10);
            max = index >= max ? index : max;
        }
    });

    return max + 1;
}

function enableDeletionAction(buttonTranslation, removeFileTooltipTranslation) {
    var mainSelector = '#' + getAttachmentsSectionId();
    $($(mainSelector + ' button').get().reverse()).each(function(key, elem){
        if (key > 0) {
            $(elem)
            .unbind('click')
            .attr('title', removeFileTooltipTranslation)
            .removeClass('btn-light')
            .addClass('btn-remove-file')
            .html(buttonTranslation)
            .on('click', function (event) {
                event.preventDefault();
                $(elem).parent().parent().remove();
            });
        }
    });
}

function addAttachmentForm(fileValidationMessages, resetUploadedAttachments = false) {
    var mainSelector = '#' + getAttachmentsSectionId();
    var index = getNextIndex();

    if (resetUploadedAttachments) {
        index = 1;
    }

    var newForm = $(mainSelector).data('prototype');
    newForm = newForm.replace(/__name__/g, index);
    $(mainSelector).append(newForm);

    $(mainSelector + '_' + index + '_file_file').rules('add', {
        maxsize: 5000000,
        accept: 'application/pdf, application/x-pdf, image/png, image/jpeg',
        messages: {
            maxsize: fileValidationMessages.maxsize,
            accept: fileValidationMessages.notValidAttachmentFile,
        }
    });

    $(mainSelector + '_' + index + '_file_file').change(function () {
        var fieldVal = "";

        if (this.files.length > 0) {
            fieldVal = this.files[0].name;
        }

        if (fieldVal != undefined && fieldVal != "") {
            $(this).parent().prev(".custom-file-label").text(fieldVal);
        } else {
            $(this).parent().prev(".custom-file-label").text(fileValidationMessages.chooseFile);
        }
    });

    $(mainSelector + '_' + index + '_action').on('click', function () {

        $('.attachment-error').remove();
        if (isValidInputRow(index)) {
            stageRow(index);
            addAttachmentForm(fileValidationMessages);
            enableDeletionAction(fileValidationMessages.removeFileButtonTranslation, fileValidationMessages.removeFileButtonTooltipTranslation);
        }
    });
}

function enableValidation() {
    $("form[name='" + getFormName() + "']").validate({
        errorClass: "custom-form-error",
        onsubmit: false,
    });
}

function removeElement(id) {
    window.document.getElementById(id).remove();
}

function resetAttachments(fileValidationMessages) {
    var index = getNextIndex() - 1;
    var attachmentsId = getAttachmentsSectionId();

    for (var i = 1; i <= index; i++) {
        removeElement(attachmentsId + '_' + i);
    }

    addAttachmentForm(fileValidationMessages, true);
}

function formSubmitHandler(messages) {
    var formName = getFormName();
    var attachmentsId = getAttachmentsSectionId();

    $('#confirmationSubmitButton').click(function () {
        $('[id*="' + attachmentsId + '_"] :input[type="file"]').last().remove();
        $('[id*="' + attachmentsId + '_"] :input[type="text"]').last().remove();

        $("form[name='" + formName + "']").submit();
    });

    $('#formSubmissionConfirmationModal').on('hidden.bs.modal', function () {
        setRequiredAttributeForFileInputs(true)
    })

    $('#formSubmissionConfirmationModal').on('show.bs.modal', function (event) {

        if (!isAttachmentWidgetEmpty()) {
            $(this).find('#modal-submit-extra').html(messages.unattachedFiles);
        } else {
            $(this).find('#modal-submit-extra').html('');
        }
    });

    $('#' + formName + '_save').on('click', function(event){
        event.preventDefault();
        setRequiredAttributeForFileInputs(false);

        if (shouldAttachmentsBeValidated() || !$("form[name='" + formName + "']").valid()) {
            setRequiredAttributeForFileInputs(true);

            $('[id*="' + attachmentsId + '_"] :input[type="file"]').first().next().remove();
            $('[id*="' + attachmentsId + '_"] :input[type="text"]').first().next().remove();

            return;
        }
        
        $("#formSubmissionConfirmationModal").modal('show');
    });
}

function shouldAttachmentsBeValidated() {
    var hasAttachmentFiles = $('input[type="file"]').length != 0;
    var isCessationRequest = $('form[name="contract_cessation_request"]').length;
    var holdersDeathSequence = $('#contract_details_request_holdersDeathSequence').is(":checked");

    return (hasAttachmentFiles && (holdersDeathSequence || isCessationRequest) && !wasFileAttached());
}

function wasFileAttached() {
    var uploadedDetailsFiles = $('#contract_details_request_documents').children().children();
    var uploadedCessationFiles = $('#contract_cessation_request_documents').children().children();
    var uploadedFiles = uploadedDetailsFiles.add(uploadedCessationFiles);

    if (uploadedFiles !== undefined && uploadedFiles.length > 1) {
        return true;
    }

    var attachmentsId = getAttachmentsSectionId();

    setRequiredAttributeForFileInputs(true);
    $('[id*="' + attachmentsId + '_"] :input[type="file"]').valid()
    $('[id*="' + attachmentsId + '_"] :input[type="text"]').valid()
    setRequiredAttributeForFileInputs(false);
    showAttachmentError();

    return false;
}

function getAttachmentsFormActionSelector() {
    var index = getNextIndex() - 1;

    return "#" + getAttachmentsSectionId() + '_' + index + '_action'
}

function showAttachmentError() {
    var currentSelector = getAttachmentsFormActionSelector();
    addMissingAttachmentErrorMessage();
    $(currentSelector).addClass('animating');
    $(currentSelector).on('webkitAnimationEnd oanimationend msAnimationEnd animationend', function() {
        $(currentSelector).removeClass('animating');
    });
}

function addMissingAttachmentErrorMessage() {
    var currentId = getAttachmentsSectionId();
    if (!$('#' + currentId).next().is('div#validation-error')) {
        $('#' + currentId).after('<div id="validation-error" class="text-center attachment-error"><label class="custom-form-error"></label></div>');
        $('#' + currentId).next().find('label').text($.validator.messages.attachment);
    }
}

function setRequiredAttributeForFileInputs(required) {
    var attachmentsId = getAttachmentsSectionId();

    $('[id*="' + attachmentsId + '_"] :input[type="file"]').last().rules('add', {required: required})
    $('[id*="' + attachmentsId + '_"] :input[type="text"]').last().rules('add', {required: required})
}

function isAttachmentWidgetEmpty() {
    var attachmentsId = getAttachmentsSectionId();

    if ($('[id*="' + attachmentsId + '_"] :input[type="file"]').last().val()
     || $('[id*="' + attachmentsId + '_"] :input[type="text"]').last().val()
    ){
        return false;
    }

    return true;
}

module.exports.enableValidation = enableValidation;
module.exports.addAttachmentForm = addAttachmentForm;
module.exports.enableDeletionAction = enableDeletionAction;
module.exports.formSubmitHandler = formSubmitHandler;
module.exports.resetAttachments = resetAttachments;
