/* DATE WIDGET CONFIGURATION */
jQuery().ready(function() {
    var datePickerOptions = {
      format: 'dd-mm-yyyy',
      todayHighlight: true,
      startDate: createDate(0, 0, 0),
      endDate: createDate(0, 0, +2),
      language: session_locale,
      autoclose: true,
    }

    $('.date_widget').datepicker(datePickerOptions);

    function createDate(days, months, years) {
        var date = new Date();
        date.setDate(date.getDate() + days);
        date.setMonth(date.getMonth() + months);
        date.setFullYear(date.getFullYear() + years);
        return date;
    }
});